<template>
    <!-- 下面的商标 -->
    <div class="bottom-footer">
        <div class="btm-ctn">
            <p><a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2021019044号-1</a></p>
            <p><a href="https://beian.miit.gov.cn/" target="_blank"><img src="https://www.boce.com/statics/web/images/beian.png" alt=""> 川公网安备51019002005399号</a></p>
            <p>Copyright成都科锐牧数字娱乐有限公司版权所有</p>
            
        </div>
    </div>
</template>

<script>
export default {
    methods: {
    }
}
</script>

<style lang="less">
.bottom-footer {
    background-color:#363636;
    padding: 20px;
    text-align: center;
    .btm-ctn {
        color: #ccc;
        a{
            color: #fff;
        }
    }
}
</style>