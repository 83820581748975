import Vue from 'vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import App from './App.vue'
import router from './router'
import store from './store'


const i18n = new VueI18n({ // 双语
  locale: 'zh',
  messages: {
    'zh': require('./components/lang/zh'),
    'th': require('./components/lang/th'),
    'en': require('./components/lang/en')
  }
})

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
