<template>
  <div class="home">
    <main>
      <div class="main">
        <div class="left">
          <img :src="$t('messages.Chinese.main.imgUrl.haidao') " @click="hdBtn()">
        </div>
        <div class="right">
          <h1>{{ $t('messages.Chinese.main.title.hd') }}</h1>
          <p>{{ $t('messages.Chinese.main.content.hd') }}</p>
          <p>{{ $t('messages.Chinese.main.contentOne.hd') }}</p>
          <p>{{ $t('messages.Chinese.main.contentTwo.hd') }}</p>
          <p>{{ $t('messages.Chinese.main.contentThree.hd') }}</p>
          <p>{{ $t('messages.Chinese.main.contentFour.hd') }}</p>
        </div>
      </div>

      <div class="main">
        <div class="left">
          <img :src="$t('messages.Chinese.main.imgUrl.shangzhai') " @click="szBtn()">
        </div>
        <div class="right">
          <h1>{{ $t('messages.Chinese.main.title.sz')  }}</h1>
          <p>{{ $t('messages.Chinese.main.content.sz') }}</p>
          <p>{{ $t('messages.Chinese.main.contentOne.sz') }}</p>
          <p>{{ $t('messages.Chinese.main.contentTwo.sz') }}</p>
        </div>
      </div>
    </main>
    <FooterView />
  </div>
</template>

<script>
import FooterView from "@/components/FooterView.vue"
export default {
  data() {
    return {
      
    }
  },
  methods: {
    hdBtn() {
      window.open("http://game.keloloo.com/")
    },
    szBtn() {
      window.open("http://yjfm.shangzhai.net.cn/")
    }
  },
  components: {
    FooterView
  }
}
</script>
<style lang="less">
main {
  width: 100%;
  height: 100%;
  margin: 30px 0;

  .main {
    margin: 0 auto 10px;
    width: 1300px;
    height: 100%;
    display: flex;
    justify-content: space-between;

    .left {
      width: 60%;
      height: 430px;

      img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }

    .right {
      width: 40%;
      padding-left: 50px;
      padding-top: 30px;

      h1 {
        color: #a10000;
      }
    }
  }
}
</style>
