<template>
  <div id="app">
    <div class="header">
      <nav>
        <div class="h-left">
          <router-link to="/home">{{ $t('messages.Chinese.nav.home') }}</router-link>
          <router-link to="/blurb">{{ $t('messages.Chinese.nav.blurb') }}</router-link>
          <router-link to="/user">{{ $t('messages.Chinese.nav.userAgreement') }}</router-link>
          <router-link to="/privacy">{{ $t('messages.Chinese.nav.privacyAgreement') }}</router-link>
          <router-link to="/contact">{{ $t('messages.Chinese.nav.about') }}</router-link>
        </div>
        <div class="h-right">
          <el-dropdown >
            <span class="el-dropdown-link">
              {{ $t('messages.Chinese.nav.language')}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="clickLang(1)">简体中文</el-dropdown-item>
              <!-- <el-dropdown-item @click.native="clickLang(2)">繁体中文</el-dropdown-item>
              <el-dropdown-item @click.native="clickLang(3)">English</el-dropdown-item> -->
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </nav>
    </div>
    <router-view />
  </div>
</template>
<script>
export default {
  methods: {
    clickLang(type) {
      if (type === 1) {
        this.$i18n.locale = 'zh'
      } else if (type === 2) {
        this.$i18n.locale = 'th'
      } else if (type === 3) {
        this.$i18n.locale = 'en'
      }
    },
  }
}
</script>
<style lang="less">
body {
  margin: 0;
  user-select: none;
}

#app {
  .header {
    background-color: #363636;

    nav {
      margin: 0 auto;
      width: 1300px;
      display: flex;
      justify-content: space-between;
      color: #fff;
      height: 80px;
      align-items: center;

      .h-left {
        display: flex;
        justify-content: space-between;
        width: 850px;
        padding-left: 90px;

        a {
          color: #fff;
          text-decoration: none;
          font-size: 18px;
        }

        .el-button {
          border: 0;
          font-size: 18px;
          color: #fff;
        }

        .router-link-active {
          color: #ffed7d;
          font-weight: bold;
        }
      }

    }

    .h-right {
      display: flex;
      align-items: center;

      .el-dropdown-link {
        cursor: pointer;
        color: #fff;
        font-size: 16px;
      }

      .el-icon-arrow-down {
        font-size: 12px;
      }
    }
  }
}
</style>
