module.exports = {
  messages: {
    Chinese:{
      nav: {
        home: '首页',
        blurb: '简介',
        userAgreement: '用户协议',
        privacyAgreement: '隐私协议',
        about: '联系我们',
        language: "简体中文"
      },
      main: {
        imgUrl: {
          shangzhai: require("../../assets/imgs/shangzhai.jpg"),
          haidao: require("../../assets/imgs/haidao.jpg")
        },
        title: {
          hd: '海盗奇兵',
          sz: "御剑封魔"
        },
        content: {
          hd: "海洋孕育万物，星空凝视众生",
          sz: "东胜神州，魔界入侵。壹时间天下大乱，魔族所到之处哀鸿遍野，民不聊生"
        },
        contentOne: {
          hd: "世界翻天覆地，唯星辰大海壹如既往。大陆的沉没，海面的上升，尘封的故事回荡在大海的波涛之中",
          sz: "各大宗门召集弟子前往神州平定动乱"
        },
        contentTwo: {
          hd: "而妳，作为被选中的船长，获得了解开这个世界的秘密的机会",
          sz: "而你将操控潜力无限的修仙"
        },
        contentThree: {
          hd: "破败的遗迹，性格各异的伙伴，故事的帷幕等著妳去揭开",
          sz: ""
        },
        contentFour: {
          hd: "那么，妳准备好「扬帆起航」了吗",
          sz: ""
        },
      },
      // 简介
      blurb: {
        title: {
          hd: "激情无限 海盗联盟",
          sz: "全球玩家 巅峰竞技"
        },
        content: {
          hd1: "挑战强力的联盟boss！",
          hd2: "抢夺海域的所有权，称霸海域！",
          hd3: "与其他船长组建最强海贼联盟，打造传奇盛宴！",
          sz1: "加入竞技场，与来自世界各地的广大玩家PK！",
          sz2: "向排行榜和最高荣耀发起猛攻，赢取强大的战利品！"
        },
        imgUrl: {
          hd1: require("../../assets/imgs/hd1.jpg"),
          hd2: require("../../assets/imgs/hd2.jpg"),
          yj1: require("../../assets/imgs/yj1.jpg"),
          yj2: require("../../assets/imgs/yj2.jpg")
        }
      },
      // 用户协议
      user: {
        title: "科锐牧服务协议",
        content: "1.简介",
        contentOne: `
        <p>1.1.欢迎使用科锐牧，如果您使用科锐牧应用程序版本，而不是在特定国家/地区（包括中国大陆、日本和韩国）分发的版本，则在这些使用条款和条件中使用（“条款”），“科锐牧”、“我们”、“我方”或“我们的”是指科锐牧 Technology Co。，有限公司及其相关附属公司在相应国家和/或地区分销科锐牧应用程序。</p>
        <p>1.2.我们为我们的用户（“科锐牧用户”）提供：</p>
        <ul>
        <li><p>1.2.1移动、桌面平台或设备的应用程序，以及可在第三方社交网络服务（“SNS”）上访问的web应用程序（统称为“科锐牧应用程序”）</p></li>
        <li><p>1.2.2与科锐牧应用程序和第三方应用程序（定义如下）相关的游戏发行商网络、众包服务和平台（“网络”）</p></li>
        <li><p>1.2.3域名和子域，包括科锐牧.com（统称为“网站”），以及科锐牧应用程序和我们的网站中的网络论坛或消息板（统称为我们的“论坛”）</p></li>
        <li><p>1.2.4我们的任何和所有科锐牧应用程序、网络、网站和论坛在本文中称为我们的“服务”。</p></li>
        </ul>
        <p>1.3请仔细阅读这些条款，因为它们管辖您访问和使用我们的服务、访问内容和用户内容（定义如下），并且具有法律约束力。</p>
        <p>1.4您确认并同意，通过点击“注册”按钮（如适用），或访问或使用我们的服务，或在我们的服务上或通过我们的服务下载或发布任何内容，表明您已阅读、理解并同意受这些条款的约束，无论您是否已注册为会员（定义如下）。如果您不同意这些条款，那么您无权访问或使用我们的服务或访问内容。某些服务以及您对某些科锐牧内容的访问或使用可能会发布不同的、特定于服务的或特定于科锐牧属性的条款和条件（“规则”），或者可能要求您同意并接受此类附加规则。如果这些条款与特定于服务的规则或特定于科锐牧内容的规则之间存在冲突，则在您使用或访问此类服务或C科锐牧属性时，适用的规则将优先。此外，科锐牧提供对第三方移动或桌面应用程序的访问,通过我们的服务提供的应用程序（各称为“第三方应用程序”），由第三方发行商（各称“第三方发行商”）设计。您理解并同意，对任何第三方应用程序的访问和使用应遵守该第三方申请程序的第三方出版商提供的使用条款和条件（“第三方条款”）。</p>
        <h3>2.修改</h3>
        <p>科锐牧有权自行决定在全球或个人基础上修改、终止或终止我们的服务，包括其中的任何部分，或在任何时候修改这些条款，而无需事先通知。如果我们修改这些条款，我们将发布修改后的条款，或以其他方式向您提供修改通知。在我们发布了对这些条款的修改或向您提供了修改通知后，继续访问或使用我们的服务，即表示您同意受修改后的条款的约束。如果您不接受修改后的条款，您唯壹的追索权就是停止使用我们的服务。</p>
        <h3>3.不得非法或禁止使用</h3>
        <p>通过使用我们的服务，您向科锐牧声明并保证，您不会将我们的服务或从我们的服务中获得的任何内容用于任何非法或本条款禁止的目的。</p>
        <h3>4.资格和注册</h3>
        <p>4.1为了访问我们服务的完整功能集，并在我们的服务上或通过我们的服务发布任何用户内容，您必须注册创建壹个科锐牧帐户（“科锐牧帐户”）（任何科锐牧账户，统称为“科锐牧帐户”）并成为“会员”</p>
        <p>4.2您同意，如果您未满18岁或您获得服务的法定成年年龄，您表示您的法定监护人已阅读、理解并同意这些条款。</p>
        <p>4.3在注册科锐牧帐户时，您同意监控该帐户以限制未成年人的任何使用。您对未成年人未经授权使用我们的服务承担全部责任，包括未成年人使用您的信用卡或其他支付或结算工具或设备。在注册过程中，您将被要求提供某些信息，包括您的电子邮件地址，并将建立用户名和密码。您同意在注册过程中提供准确、最新和完整的信息，并更新这些信息以保持其准确性、最新性和完整性。如果在注册过程中或之后提供的任何信息被证明不准确、不及时或不完整，科锐牧保留暂停或终止您的科锐牧账户的权利。</p>
        <p>4.4如果您不是会员，您只能以有限的方式浏览或使用我们的服务中不限于会员的部分。您有责任保护您的密码。您同意不向任何第三方披露您的密码，也不允许任何第三人使用您的科锐牧帐户，并且您同意对您科锐牧账户下的任何活动或行动承担全部责任，无论您是否授权此类活动或行动。您将立即通知科锐牧任何未经授权使用您的科锐牧帐户的情况。</p>
        <p>4.5尽管我们使用“您的”壹词来描述您注册的共管账户，但您同意您对共管账户没有任何所有权或其他财产权益，并且您进壹步同意，共管账户中的所有权利现在和将来都归共管所有，并以共管的利益为受益人。</p>
        <h3>5.隐私</h3>
        <p>科锐牧的隐私政策也适用于我们的服务。如果您对科锐牧隐私政策有任何疑问，请通过电子邮件与我们联系：kf@科锐牧.com</p>
        <h3>6.内容</h3>
        <p>6.1某些类型的内容可在我们的服务上或通过我们的服务获得：</p>
        <ul>
        <li><p>6.1.1用户内容是指会员通过我们的服务上传、传输或提交的文本、数据、图形、图像、照片、视频或视听内容、超文本链接以及任何其他内容。</p></li>
        <li><p>6.1.2 科锐牧内容是指文本、数据、图形、图像、插图、表格、文档、营销材料、外观属性、科锐牧或我们的许可方的商标和徽标、科锐牧应用程序和第三方应用程序，以及科锐牧在我们的服务上或通过我们的服务提供的其他内容，包括任何自定义，但不包括用户内容。</p></li>
        <li><p>6.1.3自定义是指由于成员或非注册用户使用科锐牧的交互式模板或设计工具自定义科锐牧角色而对科锐牧内容进行的修改、设计或增强。为避免疑义，“自定义设置”不包括“用户内容”。</p></li>
        </ul>
        <h3>7.所有权</h3>
        <p>7.1我们的服务和访问内容受中华人民共和国和外国的版权、商标和其他法律保护。</p>
        <p>7.2除本条款明确规定外，科锐牧和我们的许可方独家拥有服务和科锐牧内容的所有权利、所有权和权益，包括所有相关的知识产权，包括任何专利、版权、商标、服务标记、商品名、数据库权、域名权、上述任何内容的应用程序，道德权利和商业秘密权利（“知识产权”）。</p>
        <p>7.3 科锐牧将独家拥有任何定制的所有权利、所有权和权益，您特此放弃根据版权法或其他规定在任何定制中享有的任何和所有权利。您不会删除、更改或模糊我们的服务或科锐牧内容中包含或随附的任何版权、商标、服务标记或其他所有权声明。</p>
        <h3>8 科锐牧内容许可证</h3>
        <p>在您遵守这些条款的前提下，科锐牧特此授予您壹个有限的、非排他性的、不可转让的、不分许可的许可证，允许您仅出于个人和非商业目的访问、查看、下载和打印（如适用）任何科锐牧内容。除非本条款明确允许，否则您不得使用、复制、改编、修改、准备基于、分发、许可、出售、转让、公开展示、公开执行、传输、流式传输、广播或以其他方式利用服务或科锐牧内容的衍生作品。科锐牧或其许可方拥有或控制的任何知识产权下，未通过暗示或其他方式向您授予任何许可证或权利，但本条款明确授予的许可证和权利除外。</p>
        <h3>9.用户内容</h3>
        <p>9.1通过在我们的服务上或通过我们的服务提供任何用户内容，您特此向科锐牧授予壹项全球范围内的、不可撤销的、永久的、非排他性的、可转让的、免版税的许可证，并有权仅在我们的服务上、通过或通过我们服务分许可、使用、复制、改编、修改、分发、许可、出售、转让、公开展示、公开执行、传输、流式传输、广播和以其他方式利用此类用户内容。科锐牧不对任何此类用户内容主张任何所有权，本条款中的任何内容都不会被视为限制您使用和利用任何此类用户属性的任何权利。</p>
        <p>9.2您承认并同意，您对您在我们的服务上或通过我们的服务提供的所有用户内容全权负责。因此，您声明并保证：您是您在我们的服务上或通过我们的服务提供的所有用户内容的唯壹和独家所有者，或者您拥有授予科锐牧本条款项下此类用户内容权利所需的所有权利、许可、同意和发布；用户内容以及您在我们的服务上、通过我们的服务或通过我们的方式张贴、上传、发布、提交或传输用户内容或科锐牧对用户内容（或其任何部分）的使用均不会侵犯、挪用或侵犯第三方的知识产权、公开权或隐私权，或导致违反任何适用的法律或法规。</p>
        <h3>10.禁止侵权使用。</h3>
        <p>您不会使用我们的服务提供、展示、分发、传输、路由、连接或存储任何侵犯版权作品或以其他方式侵犯、侵犯或促进侵犯或侵犯任何第三方知识产权的材料。</p>
        <h3>11.评分、聊天、评论和反馈</h3>
        <p>11.1您可以对我们的服务上或通过我们的服务提供的内容进行聊天和评论（“评论”）。科锐牧建议您在留下此类评论时要谨慎行事，并作出良好判断。我们壹般不会监控、审查或缓和您的评论。壹旦您在我们的服务上或通过我们的服务完成并提交了您的评论，您就不应该期望能够返回并编辑您的评论。您也不应该期望我们代表您对您的评论进行修改或删除。</p>
        <p>11.2您应该意识到，如果您的评论被视为诽谤，您可能会对他人的声誉损害承担法律责任。在不限制这些条款的任何其他条款或条件的情况下，科锐牧可以但没有义务监督或审查评论，并否认与之相关的任何和所有责任。尽管有上述规定，科锐牧保留自行决定删除其认为不恰当、不恰当或与本条款允许的在线活动不壹致的任何评论的权利。</p>
        <p>11.3我们欢迎并鼓励您为改进我们的服务提供反馈、意见和建议（“反馈”）。您可以通过发送电子邮件至我们提交反馈kf@科锐牧.com或通过我们的服务中的反馈系统提交。</p>
        <p>11.4您承认并同意，所有反馈将是科锐牧的唯壹和排他性财产，您特此不可撤销地将您在所有反馈中的所有权利、所有权和利益，包括但不限于其中的所有全球知识产权，转让给科锐牧，并同意不可撤回地将其转让给科锐牧。此外，我们没有义务为任何反馈提供补偿。在科锐牧的要求和费用下，您将签署文件并采取科锐牧可能合理要求的进壹步行动，以协助科锐牧获得、完善和维护反馈中的知识产权和其他法律保护。</p>
        <h3>12.虚拟物品和虚拟货币</h3>
        <p>12.1 科锐牧拥有、许可或以其他方式有权使用出现或源自服务、科锐牧应用程序或第三方应用程序的所有科锐牧内容，包括虚拟物品（“虚拟物品”）和虚拟货币（“虚拟货币”）。虚拟物品和虚拟货币仅供您个人和娱乐使用，它们只能在科锐牧应用程序或第三方应用程序中使用，并且没有“真实世界”价值。通过购买或接收虚拟物品和虚拟货币，您所收到的只是根据这些条款或其他可能适用的条款在科锐牧应用程序或第三方应用程序中使用它们的有限许可证；虚拟物品和虚拟货币不是您的个人财产，它们的所有权权益不会转移给您。虚拟物品和虚拟货币的价格、数量和种类可能随时发生变化，恕不另行通知。您收到的虚拟物品和虚拟货币也可能随时更改或停止使用，恕不另行通知。</p>
        <p>12.2您不能出售或转让，或试图出售或转让虚拟物品或虚拟货币，除非在允许的情况下，您可以在服务、科锐牧应用程序和第三方应用程序中进行交换，那些不能用“现实世界”货币（“可交易物品”）购买其他可交易物品的虚拟物品和虚拟货币，只要没有为可交易物品支付或提供任何货币价值；严禁进行任何其他声称的或试图进行的交换。虚拟物品和虚拟货币可能永远不会被您兑换成“真实世界”的金钱、商品、商品、服务，或科锐牧或任何其他人的任何货币价值。</p>
        <h3>13.订单和付款</h3>
        <p>13.1您同意，如果您未满18岁或您获得服务的法定成年年龄，您只能在您的法定监护人的参与下付款，并且您表示您的法定代理人已经阅读、理解并同意这些条款。</p>
        <p>13.2您可以使用“真实世界”的资金，根据这些条款从科锐牧购买使用虚拟物品或虚拟货币的有限许可证，并且您同意所有此类购买都是最终的。如果您从科锐牧订购了虚拟物品或虚拟货币的许可证，但这些许可证在提供给您之前变得不可用，您唯壹的补救措施是向交易的支付处理器申请退还购买价格。您对虚拟物品或虚拟货币的有限许可证的订单是为使用这些虚拟物品或数字货币而提供的，如果接受，这些虚拟物品和数字货币将立即下载到您的科锐牧帐户中。您明确同意在接受订单后立即提供虚拟物品和虚拟货币。</p>
        <p>13.3如果您是欧盟居民，并且您从我们那里购买了虚拟物品和虚拟货币的有限许可证，您可能有权在购买之日起7个工作日内（“冷静期”）退出此类购买；但是，如果我方在冷静期结束前开始履约，则该提款权不适用。因此，您明确同意并理解，如果您向我们订购虚拟物品或虚拟货币的有限许可证，您的提款权在接受后即被没收，因为您的提款将在接受后立即开始。您理解并同意科锐牧不为任何购买提供退款，除非本条款中明确规定。</p>
        <h3>14.税款</h3>
        <p>您负责并将支付您或任何使用向您注册的科锐牧账户的人所产生的所有费用和适用税款。</p>
        <h3>15.移动、案头操作软件提供商和协力厂商出版商</h3>
        <p>15.1移动案头设备操作软件提供商（“作业系统提供商”）提供虚拟店面和市场，供您浏览、定位和下载移动或案头应用程序等。 如果您从作业系统提供商运营的虚拟店面或市场下载科锐牧应用程序或协力厂商应用程序，请注意，除了遵守这些条款（以及任何适用的协力厂商发行商的条款和条件）外，您还必须遵守此类虚拟店面或市场的条款和细则，例如谷歌的Android市场。</p>
        <p>15.2关于协力厂商发行商，尽管科锐牧不是您与任何协力厂商发布商就协力厂商应用程序达成的任何授权合约的一方，但您承认并同意科锐牧是每一个此类协力厂商申请程式的使用者授权合约的协力厂商受益人。 您还同意，一旦您接受任何此类协力厂商应用程序的许可条款和条件，科锐牧作为协力厂商受益人，将有权（并将被视为已接受该权利）对您强制执行此类许可。</p>
        <h3>16.用户之间的互动</h3>
        <p>16.1您全权负责与其他科锐牧用户的互动（包括任何争议）。 即使我们选择在我们的服务中提供报告用户、封锁用户或类似功能，您仍将全权负责，并且在使用我们的服务和向其他科锐牧用户披露个人资讯时必须谨慎、谨慎、常识和判断。 你同意采取合理的措施在与其他科锐牧用户的所有互动中的注意事项，特别是当您决定离线或亲自与科锐牧用户会面时。 您使用我们的服务、科锐牧内容、用户内容和通过我们的服务提供的任何其他内容的风险和自由裁量权由您自行承担，科锐牧特此不对您或任何协力厂商承担任何责任。</p>
        <p>16.2 科锐牧保留根据适用法律联系会员的权利，以评估是否遵守这些条款和任何其他适用规则。 您将与科锐牧充分合作，调查任何涉嫌非法、欺诈或不当活动，包括但不限于授权科锐牧代表访问您的科锐牧帐户中受密码保护的部分。</p>
        <h3>17.一般禁令</h3>
        <p>17.1您同意在使用我们的服务、访问内容或用户内容时不做以下任何事情：</p>
        <ul>
        <li><p>17.1.1张贴、上传、发布、提交或传输任何文字、图形、影像、软件、音乐、音讯、视频、资讯或其他资料：侵犯、挪用或侵犯协力厂商的知识产权、公开权或隐私权；</p></li>
        <li><p>17.1.2违反或鼓励任何可能违反任何适用法律或法规或可能引起民事责任的行为； 具有欺诈性、虚假性、误导性或欺骗性； 具有诽谤性、淫秽、色情、粗俗或冒犯性； 宣扬对任何个人或群体的歧视、偏执、种族主义、仇恨、骚扰或伤害； 具有暴力或威胁性，或宣扬暴力或威胁他人的行为； 或促进非法或有害活动或物质（包括但不限于促进或提供有关制造或购买非法武器或非法物质的指导资讯的活动）。</p></li>
        <li><p>17.1.3未经科锐牧明确书面同意，使用、展示、镜像、装帧或利用装帧科技将我们的服务或我们服务中的任何单个元素或资料、科锐牧的名称、任何科锐牧或科锐牧许可人的商标、徽标或其他专有信息、任何文字的内容或页面上包含的任何页面或表格的布局和设计封装起来；</p></li>
        <li><p>17.1.4访问、篡改或使用我们服务的非公共区域、科锐牧的电脑系统或科锐牧提供商的科技交付系统；</p></li>
        <li><p>17.1.5试图探测、扫描或测试任何科锐牧系统或网络的漏洞，或违反任何安全或身份验证措施；</p></li>
        <li><p>17.1.6避免、绕过、删除、停用、损害、解密或以其他管道规避科锐牧或科锐牧的任何提供商或任何其他协力厂商（包括另一科锐牧用户）为保护我们的服务或科锐牧内容而实施的任何科技措施；</p></li>
        <li><p>17.1.7试图通过使用任何引擎、软件、工具、代理访问或搜索我们的服务或科锐牧内容，或从我们的服务下载科锐牧内容， 科锐牧或其他普遍可用的协力厂商网络浏览器（如Google Chrome、Microsoft Internet Explorer、Mozilla Firefox、Apple Safari或Opera）提供的软件和/或搜索代理之外的设备或机制（包括蜘蛛、机器人、爬虫、数据挖掘工具等）；</p></li>
        <li><p>17.1.8发送任何未经请求或授权的广告、促销资料、电子邮件、垃圾邮件、垃圾邮件、连锁信或其他形式的招揽；</p></li>
        <li><p>17.1.9未经科锐牧明确书面同意，利用科锐牧或科锐牧许可人的商标、徽标URL或产品名称使用任何元标签或其他隐藏文字或中继资料；</p></li>
        <li><p>17.1.10将我们的服务或科锐牧内容用于任何商业目的或任何协力厂商的利益，或以本条款不允许的任何管道使用；</p></li>
        <li><p>17.1.11在任何电子邮件或新闻组张贴中伪造任何TCP/IP数据包报头或报头资讯的任何部分，或以任何管道伪造我们的服务或科锐牧内容，以发送更改的、欺骗性的或虚假的来源识别资讯；</p></li>
        <li><p>17.1.12试图解密、反编译、反汇编或反向工程任何用于提供我们的服务或内容的软件； 干扰或试图干扰任何用户、主机或网络的访问，包括但不限于发送病毒、超载、泛滥、垃圾邮件或邮件轰炸我们的服务；</p></li>
        <li><p>17.1.13未经我们服务的其他用户明确许可，从他们那里收集或存储任何个人身份资讯； 冒充或歪曲您与任何个人或实体的关系；</p></li>
        <li><p>17.1.14违反任何适用的法律或法规； 鼓励或允许任何其他个人做上述任何事情；</p></li>
        <li><p>17.1.15发布用户内容或采取任何侵犯或侵犯另一会员权利的行动：欺凌、骚扰或恐吓任何服务会员； 向另一会员索取会员登录凭证，或收集用户内容，或通过自动化管道访问服务，包括但不限于机器人、机器人、蜘蛛；</p></li>
        <li><p>17.1.16为您以外的任何人创建科锐牧帐户；</p></li>
        <li><p>17.1.17利用您的会员档案获取个人商业利益；</p></li>
        <li><p>17.1.18使用欺骗、漏洞利用、骇客攻击、机器人程式、MOD或协力厂商软件，旨在获得相对于其他会员的优势，无论是感知的还是实际的，或修改或干扰服务；</p></li>
        <li><p>17.1.19滥用或利用服务中的漏洞、故障或机制； 或从事任何欺诈行为，包括但不限于信用卡诈骗或挪用信用卡。</p></li>
        <li><p>17.1.20在官方科锐牧论坛上发布垃圾邮件或转发删除的内容。 这包括但不限于多次发布相同内容，或转发已从论坛中删除的帖子或主题。</p></li>
        <li><p>17.1.21不体育行为。 帐户共亯，包括但不限于共亯用户名和密码，供他人代为登入。</p></li>
        </ul>
        <p>17.2 科锐牧有权在法律的最大范围内调查和起诉违反上述任何一项的行为，包括侵犯智慧财产权和服务安全问题。 科锐牧可能会与执法部门合作，起诉违反这些条款的用户。</p>
        <p>17.3 您承认科锐牧没有义务监控或记录您对我们的服务或科锐牧内容的访问或使用，也没有义务监控、记录或编辑任何用户内容，但同意我们有权这样做是为了运营我们的服务，确保您遵守这些条款，或遵守适用法律或法院的命令或要求， 行政机构或其他政府机构。 您承认并同意，您对任何用户内容的上传、传输或提交不存在任何隐私期望。</p>
        <p>17.4 科锐牧保留在任何时候删除或禁止访问科锐牧自行认为违反本条款或对我们的服务有害的任何用户内容的权利，恕不另行通知。 我们鼓励科锐牧用户向我们发送电子邮件，举报任何涉嫌不当行为或滥用我们服务的行为 kf@科锐牧.com</p>
        <h3>18.连结和协力厂商资料连结和第二方资料连结及协力厂商资料</h3>
        <p>18.1我们的服务可能包含指向协力厂商网站或资源的连结。 您承认并同意科锐牧不对以下内容负责：（i）此类网站或资源的可用性或准确性； 或（ii）此类网站或资源上的内容、产品或服务，或可从这些网站或资源获得的内容、商品或服务。 到此类网站或资源的连结并不意味著科锐牧对此类网站、资源或此类网站或来源的内容、产品或服务的任何认可。</p>
        <p>18.2您承认对您使用任何此类网站或资源所产生的所有风险承担全部责任。 如果您选择在移动、案头运营商网络上使用我们的服务，将适用该运营商的正常计费率。 此外，通过我们的服务提供的某些内容、产品和服务可能包括来自协力厂商的资料，包括协力厂商应用程序。</p>
        <p>18.3您同意科锐牧不负责检查或评估协力厂商内容或此类内容的准确性，科锐牧对任何协力厂商应用程序或协力厂商的任何其他资料、产品或服务不保证也不承担任何责任。</p>
        <p>18.4您同意，您不会以侵犯或侵犯任何其他方权利的管道使用任何协力厂商应用程序或其他协力厂商资料，科锐牧对您的任何此类使用不承担任何责任。</p>
        <h3>19.抽奖和竞赛</h3>
        <p>科锐牧可允许通过服务提供抽奖、竞赛和类似促销（统称为“促销”）。 您应仔细审查您通过服务参与的每个促销活动的规则（如“官方规则”），因为这些规则可能包含关于科锐牧对您作为促销活动的一部分以及您参与此类促销活动所提交的资料的权利和所有权的其他重要资讯。 如果此类官方规则的条款和条件与本条款相冲突，则以此类官方规则中的条款和条款为准。</p>
        <h3>20.遗产帐户的终止</h3>
        <p>20.1在不限制其他补救措施的情况下，如果科锐牧怀疑或自行决定您可能存在或存在以下重大风险，科锐牧可随时暂停或终止您的科锐牧帐户，并拒绝提供我们的服务：（i）未遵守本条款的任何规定或科锐牧制定的任何政策或规则； （ii）参与与我们的服务有关或在使用我们的服务的过程中可能是非法的行为，或对您、科锐牧用户、科锐牧或任何其他协力厂商或我们的服务造成责任、伤害、尴尬、骚扰、滥用或干扰； 或（iii）侵犯任何人的所有权、隐私权或知识产权，包括作为重复侵权人。</p>
        <p>20.2此外，如果发生上述任何情况，科锐牧可以通知当局或采取其认为适当的任何其他行动，而无需通知您。 您可以随时以任何理由终止您的科锐牧帐户，方法是发送电子邮件至 kf@科锐牧.com</p>
        <h3>21.帐户终止、暂停的影响</h3>
        <p>21.1一旦您或我们因任何原因终止您的科锐牧帐户，您将失去对该帐户的所有存取权限。 终止的科锐牧帐户无法恢复； 您在科锐牧帐户终止后可能注册的任何科锐牧帐户都是唯一的帐户。</p>
        <p>21.2如果您或我们出于任何原因终止了您的科锐牧帐户，您理解并同意，在终止时您通过科锐牧帐户访问的任何虚拟物品都将遗失，您将不再可以使用，您也无权使用这些虚拟物品。 如果您或我们出于任何原因终止您的科锐牧帐户，您理解并同意科锐牧可以在终止时出于其选择的任何目的赎回和使用科锐牧帐户中的虚拟货币，并且在终止时您将无权使用该虚拟货币。 此外，如果您的科锐牧帐户（非活动帐户）已有3年没有任何活动，您理解并同意（i）科锐牧可以随时出于其选择的任何目的兑换和使用非活动帐户中的虚拟货币，并且在该帐户成为非活动帐户时，您将无权使用该虚拟货币； 以及（ii）您通过非活动帐户访问的任何虚拟项目，直到该帐户成为非活动帐户，都将遗失，您将不再可以使用这些虚拟项目，并且您将无权使用这些项目。</p>
        <p>21.3您同意，无论出于何种原因，科锐牧都不需要退款，并且您不会因非活动帐户或终止科锐牧帐户中未使用的虚拟物品或虚拟货币而获得金钱或其他补偿，无论这两者是如何产生的。 在任何终止之后，您理解并承认，我们将不再有义务提供我们的服务，这些条款授予您的所有许可证和其他权利将立即停止。 科锐牧对您或任何协力厂商终止我们的服务或终止您使用我们的服务不承担任何责任。</p>
        <p>21.4在您的主访问帐户终止或暂停后，您在我们的服务上提交的任何内容、资料或资讯（包括用户内容）或与您的主帐户相关的内容、资料和资讯可能不再被您访问。 此外，科锐牧没有义务维护我们资料库中存储的与您的科锐牧帐户有关的任何资讯，也没有义务将任何资讯转发给您或任何协力厂商。 任何暂停、终止或取消都不会影响您在这些条款下对科锐牧的义务（包括但不限于所有权和所有权、赔偿和责任限制），根据其意义和上下文，这些义务将在暂停、终止和取消后继续有效。</p>
        <h3>22.免责声明</h3>
        <p>我们的服务、访问内容和用户内容都是按原样提供的，没有任何明示或暗示的保证。 在不限制上述规定的情况下，科锐牧明确否认任何关于适销性、特定用途的适用性、安静享受或不侵权的保证，以及交易过程或贸易使用过程中产生的任何保证。 Coloss不保证我们的服务、Coloss内容或用户内容将满足您的要求，或在不间断、安全或无错误的基础上提供。 对于通过我们的服务购买或获得的任何产品、服务或内容的质量，或通过我们服务获得的任何内容的准确性、及时性、真实性、完整性或可靠性，科锐牧不作任何保证。 从Coloss或通过我们的服务、Coloss内容或用户内容获得的任何建议或资讯，无论是口头还是书面，都不会产生本协定未明确规定的任何保证。</p>
        <h3>23.赔偿</h3>
        <p>您同意保护、赔偿并保护科锐牧及其高级职员、董事、员工和代理人免受因您提交给科锐牧的用户内容、您对我们的服务或科锐牧内容的访问而产生的或以任何管道与之相关的任何索赔、责任、损害、损失和费用，包括但不限于合理的法律和会计费用， 或您违反这些条款。</p>
        <h3>24.责任限制</h3>
        <p>您承认并同意，在法律允许的最大范围内，您访问和使用我们的服务、访问内容和其中的用户内容所产生的全部风险仍由您承担。 科锐牧或参与创建、生产或交付我们的服务或科锐牧内容的任何其他方均不对任何附带、特殊、惩戒或后果性损害负责，包括利润损失、数据损失或商誉损失、服务中断、电脑损坏或系统故障或替代产品或服务的成本，由这些条款引起或与之相关，或由使用或无法使用我们的服务或其中的内容引起，无论是基于保证、契约、侵权行为（包括疏忽）、产品责任还是任何其他法律理论， 以及是否已告知科锐牧发生此类损害的可能性，即使本协议规定的有限补救措施未能达到其基本目的。 您明确承认Colosse不对其他用户或协力厂商的诽谤、冒犯或非法行为，上述行为造成的伤害风险完全由您承担。 此外，对于上传到我们的服务上或从我们的服务下载的任何用户内容或协力厂商内容，科锐牧对您或任何协力厂商不承担任何责任。 在任何情况下，科锐牧的总负债都不会因这些而产生或与之相关使用或无法使用我们的服务或访问其中的内容或用户内容，或由此提供的服务或内容超过一百美元（100美元）。 上述损害赔偿的限制是科洛塞斯与您之间交易基础的基本要素。 某些司法管辖区不允许排除或限制间接或附带损害的责任，囙此上述限制可能不适用于您。</p>
        <h3>25.商标和其他所有权声明</h3>
        <p>科锐牧或我们的许可方在与服务相关的情况下使用的所有商标、服务标记、徽标、商品名和任何其他专有名称均为Coloc塞斯或我们的授权方的商标或注册商标。 任何其他商标、服务标志、徽标、商品名和任何其他专有名称均为其各自所有者的商标或注册商标。</p>
        <h3>26.控制法律和管辖权</h3>
        <p>本条款及与之相关的任何行动均受中华人民共和国法律管辖，不考虑其法律规定的冲突。 因本条款引起的或与本条款有关的任何争议应提交上海国际经济贸易仲裁委员会/上海国际仲裁中心（“SHIAC”）进行仲裁，仲裁应按照申请仲裁时有效的SHIAC仲裁规则进行。 仲裁地点应为中国上海。 仲裁程式使用的语言应为中文。 仲裁裁决是终局的，对双方均有约束力。</p>
        <h3>27.完整协定</h3>
        <p>这些条款构成科锐牧和您之间关于我们的服务和科锐牧内容的完整和排他性的理解和协定，并且这些条款取代和取代科锐牧与您之间之前关于我们的服务和科锐牧内容的任何和所有口头或书面理解或协定。</p>
        <h3>28.转让</h3>
        <p>未经科锐牧事先书面同意，您不得通过法律或其他管道转让或转让这些条款。 未经您同意，您转让或转让本条款的任何尝试均无效。 科锐牧可以自由转让这些条款。 在符合上述规定的情况下，本条款将对双方、其继承人和许可受让人具有约束力，并使其受益。</p>
        <h3>29.通知</h3>
        <p>29.1您同意使用：（i）电子管道完成本条款，并交付本条款允许或要求的任何通知或其他通信； 以及（ii）存储与本条款或您使用我们的服务有关的资讯的电子记录。</p>
        <p>29.2本协定项下允许或要求的任何通知或其他通信，包括与本条款修改有关的通知或通信，均应以书面形式发出：（i）科锐牧通过电子邮件（在任何情况下均发送至您提供的地址）或（ii）在我们的服务上或通过我们的服务张贴。 对于通过电子邮件发出的通知，接收日期将被视为该通知的发送日期。</p>
        <h3>30.概述</h3>
        <p>30.1 科锐牧未能执行本条款中的任何权利或规定，不构成对未来执行该权利或规定的放弃。 对任何此类权利或规定的弃权只有以书面形式并由科锐牧的正式授权代表签字方可生效。</p>
        <p>30.2除非本条款另有明确规定，否则任何一方行使本条款项下的任何补救措施均不影响其在本条款或其他方面的其他补救措施。 如果出于任何原因，具有管辖权的法院认定本条款的任何条款无效或不可执行，则该条款将在允许的最大范围内执行，而本条款的其他条款仍将完全有效。</p>
        <p>30.3您同意：（i）本条款适用于科锐牧 Technology Co.，Ltd（“关联出版商”）的任何协力厂商出版商； （ii）除非根据任何协力厂商条款与任何此类关联出版商另有约定，否则您在这些条款下对科锐牧的义务适用于关联出版商； 以及（iii）关联出版商是本条款项下的协力厂商受益人，他们可能依赖并直接对您执行本条款。</p>
        <h3>31.联系我们</h3>
        <p>如果您对这些条款有任何疑问，请联系科锐牧，网址为 kf@科锐牧.com</p>
                 `
      },
      // 隐私协议
      duty: {
        title: "科锐牧隐私政策",
        content: "引言",
        footer: "成都科锐牧有限公司",
        contentOne: `
        <p>如果您使用科锐牧 Applications版本，而不是在中国大陆、日本和韩国等特定国家/地区分发的版本，则您承认已阅读并理解本隐私政策。如果您不同意此隐私政策（“此政策”），则不得使用科锐牧服务条款。如果您将来改变主意，您可以根据本政策撤回对使用您的个人信息的同意。您同意，如果您未满18岁或您获得服务的法定成年年龄，您表示您的法定监护人已阅读、理解并同意本政策。</p>
        <p>在科锐牧，我们知道您的隐私对您非常重要，所以请花时间仔细阅读我们的隐私政策。我们的政策旨在帮助您理解</p>
        <p>1、 我们收集了哪些关于您的信息；</p>
        <p>2、 我们将如何使用有关您的信息；</p>
        <p>3、 我们与谁共享您的信息；</p>
        <p>4、 我们掌握您的信息多长时间；</p>
        <p>5、 我如何行使对我的信息的权利；</p>
        <p>6、 争议解决；</p>
        <p>7、 我们将如何通知您变更；</p>
        <p>8、 联系信息；</p>
        <p>本隐私政策是科锐牧科技有限公司提供的科锐牧服务条款不可分割的壹部分。本政策适用于直接引用或链接本政策的所有科锐牧服务条款，但不适用于具有不包含本政策的单独隐私政策的科锐牧服务条款</p>
        <h3>第壹节：我们是数据控制器</h3>
        <p>1.我们使用的个人信息类型</p>
        <p>本节介绍了我们收集的不同类型的个人信息以及我们如何收集这些信息。我们将收集和使用以下关于您的信息：</p>
        <p>1.1.您向我们提供的信息。</p>
        <ul>
        <li><p>a） 当您注册游戏时（包括您指定的昵称，或我们从您连接的社交媒体帐户（微信、脸书、谷歌）导入的自定义帐户和信息，以设置您的个人资料，包括您在社交媒体个人资料和个人资料图片上显示的姓名）；</p></li>
        <li><p>b） 当您以“访客”身份登录以使用游戏（您的昵称）时；</p></li>
        <li><p>c） 当您参与游戏中的调查时（在这种情况下，我们将处理您作为调查的壹部分提供给我们的信息）</p></li>
        <li><p>d） 当您通过我们的服务（如适用）或与我们授权的第三方支付服务提供商进行交易时。</p></li>
        <li><p>e） 当您根据提供商的政策将您的账户与您的社交媒体账户（微信、脸书、谷歌等）或其他外部账户链接或使用其他社交媒体功能时。</p></li>
        <li><p>f） 当您使用我们的服务或通过我们的服务与其他用户和/或我们互动时。这包括但不限于您与我们互动时可能生成的cookie。</p></li>
        </ul>
        <p>1.2.我们收集的有关您的信息。</p>
        <ul>
        <li><p>a） 名称；</p></li>
        <li><p>b） 生成的用户名和打开id；</p></li>
        <li><p>c） 地址、电子邮件地址、电话号码（“联系信息”）；</p></li>
        <li><p>d） 交易信息；</p></li>
        <li><p>e） 年龄、性别、出生日期；</p></li>
        <li><p>f） 照片；</p></li>
        <li><p>g） 使用数据和交易数据；</p></li>
        <li><p>h） 位置相关信息，包括地理位置和IP地址；</p></li>
        <li><p>i） 调查内容和答复；</p></li>
        <li><p>j） 广告ID；</p></li>
        <li><p>k） 客户支持票证ID和用户与支持的通信；</p></li>
        <li><p>l） 游戏统计（等级，分数/s）；</p></li>
        <li><p>m） 设备信息（如应用程序版本、网络类型、操作系统）；</p></li>
        <li><p>n） 营销和沟通数据，如您对接收营销材料的偏好、您的沟通偏好以及您与我们和我们的服务提供商的沟通历史。</p></li>
        <li><p>o） 聊天数据；和</p></li>
        <li><p>p） 安全相关信息。</p></li>
        </ul>
        <p>1.3.我们从合作伙伴那里收集的数据。</p>
        <ul>
        <li><p>a） 如果您将第三方工具与服务（如微信、脸书、谷歌）链接，我们将收到数据。如果您使用您的社交媒体账户（如微信、脸书、谷歌等）注册为我们服务的用户，并将您的账户链接到您的社交社交媒体账户，我们可能会根据社交媒体账户提供商的政策访问您自愿提供给该提供商的有关信息，我们将根据本政策管理和使用这些个人数据。</p></li>
        <li><p>b） 人口统计数据（例如确定Ip地址的粗略位置）</p></li>
        <li><p>c） 打击欺诈的数据（如游戏中滥用退款或广告中的点击欺诈）</p></li>
        <li><p>d） 来自游戏运行平台的数据（例如验证支付）</p></li>
        <li><p>e） 用于广告和分析目的的数据，因此我们可以为您提供更好的服务</p></li>
        </ul>
        <p>1.4. Cookies.</p>
        <p>我们使用Cookie和其他类似技术（如网络信标、日志文件、脚本和电子标签）（“Cookie”）来增强您使用科锐牧服务的体验。Cookie是小文件，当它们放在您的设备上时，使我们能够提供某些功能。您可以选择允许安装此类Cookie或随后禁用它们。您可以接受所有cookie，或指示设备（在游戏的情况下）在安装cookie时发出通知，或通过调整设备中的相关cookie保留功能来拒绝接受所有cookie。但是，如果您拒绝安装cookie，游戏可能无法按设计运行。</p>
        <p>2.我们如何使用您的个人信息</p>
        <p>2.1我们将通过以下方式使用这些信息：</p>
        <ul>
        <li><p>2.1.1根据您的请求为游戏创建您的帐户；</p></li>
        <li><p>2.1.2生成用户名和打开id：将您的游戏数据（包括级别和进度）与您的个人资料壹起存储；</p></li>
        <li><p>2.1.3通过电话、短信和/或传真、电子邮件和/或邮政邮件或其他方式与您联系或沟通，以管理和/或管理您与我们的关系或您对我们服务的使用；</p></li>
        <li><p>2.1.4保存您的交易历史记录；</p></li>
        <li><p>2.1.5管理、运营、提供和/或管理您对我们服务的使用和/或访问（包括但不限于您的偏好），以及您与我们的关系和账户；</p></li>
        <li><p>2.1.6用于识别和/或验证；（如适用）</p></li>
        <li><p>2.1.7进行研究、分析和开发活动（包括但不限于数据分析、调查、产品和服务开发和/或分析），分析您如何使用我们的服务，改进我们的服务或产品和/或增强您的客户体验；</p></li>
        <li><p>2.1.8如果您启用聊天服务（音频或文本），我们将处理这些数据，以便将您的消息传递给其他用户；</p></li>
        <li><p>2.1.9出于安全和核查目的；识别和解决漏洞，评估游戏功能以优化行动；解决游戏崩溃并优化设备与游戏的兼容性；打击用户注册多个账户；</p></li>
        <li><p>2.1.10允许其他用户与您互动、交流或连接，包括在其他用户通过游戏或服务向您发送私人消息或发表评论时通知您；</p></li>
        <li><p>2.1.11在您的管辖范围内或管辖范围外存储、托管、备份您的个人数据（无论是用于灾难恢复还是其他目的）；</p></li>
        <li><p>2.1.12根据法律或监管义务或我们的风险管理程序进行尽职调查或其他筛选活动（包括但不限于背景调查），这些程序可能是法律要求的或我们已经实施的；</p></li>
        <li><p>2.1.13响应法律程序或遵守任何相关司法管辖区的任何适用法律、政府或监管要求，包括但不限于满足对我们或相关公司或附属公司有约束力的任何法律要求下的披露要求；</p></li>
        <li><p>2.1.14在适用法律允许的情况下，为任何营销活动进行营销和广告宣传，并在这方面推荐与您的利益相关的产品和/或服务，通过各种媒介和模式（包括电子邮件或短信）向您发送与我们（和/或其附属公司或相关公司）可能正在销售、营销或推广的产品和/或服务（包括但不限于我们可能合作或合作的第三方的产品和（或）服务）有关的通信营销和促销信息和材料，无论这样的产品或服务现在存在还是将来创建。您可以使用电子营销材料中的取消订阅功能随时取消订阅接收营销信息。我们可能会使用您的联系信息发送我们和相关公司的时事通讯或营销材料；</p></li>
        <li><p>2.1.15适用法律允许的其他目的，并且我们在获得您的同意时通知您。</p></li>
        </ul>
        <p>此类目的可能并非如此，因为我们收集/可能收集、使用、披露或处理您的个人数据的目的取决于当前情况。但是，除非隐私法允许在未经您同意的情况下处理适用数据，否则我们将在获得您的同意时通知您其他目的。
        </p>
        <p>2.2分析、简介和划分。 </p>
        <p>在上述所有情况和目的下，我们可以分析、简介和划分所有收集的数据。</p>
        <p>2.3处理的法律依据</p>
        <p>如果您是欧盟居民，我们将根据许多法律依据来处理有关您的信息。例如，我们将在征得您同意的情况下，在我们有合法利益的情况下处理您的信息，在履行与您的合同所需的情况下进行处理，以及在我们有法律义务处理您信息的情况下对您的信息进行处理。例如，我们依靠我们的合法利益来服务于有针对性的营销，以及在您创建帐户时处理信息的合同必要性。要了解更多关于我们出于任何特定目的处理您的信息所依据的法律依据，请通过下面的联系和投诉中的选项与我们联系。</p>
        <p>3.我们如何共享您的个人信息</p>
        <p>3.1共享。我们重视对您个人信息的保护，这是我们为您提供产品和服务的重要基础和组成部分。我们只会出于本政策的目的和范围内或根据法律法规的要求收集和使用您的个人信息，并对其严格保密。壹般情况下，我们不会与任何公司、组织或个人共享您的个人信息，以下情况除外：</p>
        <ul>
        <li><p>（a） 事先征得您的同意；</p></li>
        <li><p>（b） 适用法律、法规、法律程序、政府强制或司法决定要求共享您的个人信息；</p></li>
        <li><p>（c） 在法律要求或允许的范围内，有必要向第三方提供您的个人信息，以保护我们、其用户或公众免受其利益、财产或安全的损害；</p></li>
        <li><p>（d） 您的个人信息可能会在我们的附属公司之间共享。我们只会分享必要的个人信息，此类分享受本政策所述目的的约束。如果附属机构希望更改处理个人信息的目的，它将再次征求您的授权同意；</p></li>
        <li><p>（e） 为了向您提供改进的、高质量的产品和服务，我们的部分服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的壹些个人信息，以提供更好的客户服务和用户体验。我们只会出于合法、合法、必要、具体和明确的目的分享您的个人信息，并且只会分享提供服务所需的个人信息。我们还要求我们的合作伙伴根据我们的指示、本政策以及任何其他相关的保密和安全措施处理您的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他目的。如果您拒绝允许我们的合作伙伴收集提供服务所需的个人信息，您可能无法在我们的平台上使用该第三方服务。</p></li>
        </ul>
        <p>我们的合作伙伴收集和处理信息受其自己的隐私政策或相关声明的约束，这些政策不适用于本政策。为了最大限度地保护您的信息安全，我们建议您在使用任何第三方服务之前审查其隐私政策。为了保护您的合法权益，如果您发现相关第三方的服务存在风险，我们建议妳立即终止相关操作并及时与我们联系。</p>
        <p>我们目前有权访问以下第三方合作伙伴权限：https://www.科锐牧.com/3rdsdk?locale=en-US</p>
        <p>3.2转让。我们不会将您的个人信息转移给任何公司、组织或个人，以下情况除外：</p>
        <ul>
        <li><p>a） 在获得明确同意的情况下转移：在获得您的明确同意后，我们将把您的个人信息转移给其他方；</p></li>
        <li><p>b） 根据适用法律法规、法律诉讼要求以及必要的强制性行政或司法要求提供；</p></li>
        <li><p>c） 如果发生涉及个人信息转让的合并、收购、资产转让、破产和清算或类似交易，我们将要求持有您个人信息的新公司或组织继续受本政策约束，然后再要求该公司或组织再次征求您的授权同意。</p></li>
        </ul>
        <p>3.3披露。我们只会在以下情况下公开披露您的个人信息：</p>
        <ul>
        <li><p>a） 在获得您的明确同意后；</p></li>
        <li><p>b） 基于法律的披露：如果法律、法律程序、诉讼或政府当局强制要求，我们可能会公开披露您的个人信息。</p></li>
        </ul>
        <p>3.4未经您事先同意。根据相关法律法规和国家标准，在以下情况下，我们可以在未经您事先授权同意的情况下共享、转移和公开披露个人信息：</p>
        <ul>
        <li><p>a） 与我们履行法律法规规定的义务有关；</p></li>
        <li><p>b） 直接关系到国家安全和国防安全；</p></li>
        <li><p>c） 与公共安全、公共卫生和重大公共利益直接相关的；</p></li>
        <li><p>d） 与犯罪侦查、起诉、审判、执行判决等直接相关的；</p></li>
        <li><p>e） 为维护个人信息主体或其他个人的生命、财产和其他重大合法权益，但难以征得其同意的；</p></li>
        <li><p>f） 个人信息主体本人向公众披露的个人信息；</p></li>
        <li><p>g） 从合法公开披露的信息中收集的个人信息，如法律新闻报道、政府信息披露和其他渠道。</p></li>
        </ul>
        <p>根据法律规定，在数据接收方无法恢复和重新识别信息主体的情况下，共享、转移或公开披露已被取消身份的个人信息不应被视为共享、转移和公开披露个人信息，此类数据应在未通知您或未经您同意的情况下进行存储和处理。</p>
        <p>4.数据保留</p>
        <p>4.1我们将按照以下说明保留您的个人信息（除非适用法律另有要求）。</p>
        <div class="table" style="width: 1300px; margin: 30px auto;">
        <table align="center" style="width: 100%; border: 1px solid black; border-collapse: collapse; font-size:14px; padding:20px;">
        <tbody style="height: 50px; ">
        <th style="border: 1px solid black; width: 45%;">个人信息</th>
        <th style="border: 1px solid black; width: 55%;">保留策略</th>
        </tbody>
        <tr>
        <th style="border: 1px solid black; width: 45%; padding:20px;">昵称</th>
        <th style="border: 1px solid black; width: 55%;padding:20px;">在您使用游戏的整个生命周期内存储（即，直到根据您的请求删除帐户为止），然后在30天内删除。</th>
        </tr>
        <tr>
        <th style="border: 1px solid black; width: 45%;padding:20px;">社交连接信息：微信</th>
        <th style="border: 1px solid black; width: 55%;padding:20px;">在您使用游戏的整个生命周期内存储（即，直到根据您的请求删除帐户或您撤销对社交媒体帐户的访问），然后在30天内删除。</th>
        </tr>
        <tr>
        <th style="border: 1px solid black; width: 45%;padding:20px;">社交连接信息：Facebook</th>
        <th style="border: 1px solid black; width: 55%;padding:20px;">在您使用游戏的整个生命周期内存储，直到帐户应您的要求被删除，之后它将在15天内被删除。如果您不要求删除帐户，此数据将在游戏关闭或我们不再使用此数据后120天内删除。</th>
        </tr>
        <tr>
        <th style="border: 1px solid black; width: 45%;padding:20px;">游戏统计数据（等级，分数/s）</th>
        <th style="border: 1px solid black; width: 55%;padding:20px;">在您使用游求删除帐户，则在游戏关闭后删除之前，这些数据将保留1年。戏的整个生命周期内存储，直到根据您的请求删除帐户，然后将在30天内删除。如果您没有请</th>
        </tr>
        <tr>
        <th style="border: 1px solid black; width: 45%;padding:20px;">聊天通信（音频信息）</th>
        <th style="border: 1px solid black; width: 55%;padding:20px;">在我们的服务器上存储30天，到期后自动删除。</th>
        </tr>
        <tr>
        <th style="border: 1px solid black; width: 45%;padding:20px;">聊天通信（文本）</th>
        <th style="border: 1px solid black; width: 55%;padding:20px;">在您使用游戏的整个生命周期内存储，直到根据您的请求删除帐户，帐户将在7天内删除。如果您没有请求删除帐户，则在游戏关闭后删除之前，这些数据将保留1年。</th>
        </tr>
        <tr>
        <th style="border: 1px solid black; width: 45%;padding:20px;">OpenID</th>
        <th style="border: 1px solid black; width: 55%;padding:20px;">在您使用游戏的整个生命周期内存储，直到根据您的请求删除帐户，之后将在15天内删除。如果您没有请求删除帐户，则在游戏关闭后删除之前，这些数据将保留1年。</th>
        </tr>
        <tr>
        <th style="border: 1px solid black; width: 45%;padding:20px;">IP地址</th>
        <th style="border: 1px solid black; width: 55%;padding:20px;">在您使用游戏的整个生命周期内存储，直到根据您的请求删除帐户，之后将在15天内删除。如果您没有请求删除帐户，则在游戏关闭后删除之前，这些数据将保留1年。</th>
        </tr>
        <tr>
        <th style="border: 1px solid black; width: 45%;padding:20px;">设备ID，设备信息</th>
        <th style="border: 1px solid black; width: 55%;padding:20px;">在您使用游戏的整个生命周期内存储，直到根据您的请求删除帐户，然后将在30天内删除。如果您没有请求删除帐户，则在游戏关闭后删除之前，这些数据将保留1年。</th>
        </tr>
        <tr>
        <th style="border: 1px solid black; width: 45%;padding:20px;">广告ID</th>
        <th style="border: 1px solid black; width: 55%;padding:20px;">在您使用游戏的整个生命周期内存储，直到根据您的请求删除帐户，之后将在15天内删除。如果您没有请求删除帐户，则在游戏关闭后删除之前，这些数据将保留1年。</th>
        </tr>
        <tr>
        <th style="border: 1px solid black; width: 45%;padding:20px;">交易记录</th>
        <th style="border: 1px solid black; width: 55%;padding:20px;">在您使用游戏的整个生命周期内存储（即，直到根据您的请求删除帐户为止），然后在为遵守我们的法律义务而必须保留此类数据的任何保留期内存储。</th>
        </tr>
        <tr>
        <th style="border: 1px solid black; width: 45%;padding:20px;">客户支持票证ID和用户与支持的通信</th>
        <th style="border: 1px solid black; width: 55%;padding:20px;">支持数据将在您提出删除此类数据的合法请求后30天内删除（请参阅本隐私政策的“删除”部分）。</th>
        </tr>
        <tr>
        <th style="border: 1px solid black; width: 45%;padding:20px;">安全相关信息</th>
        <th style="border: 1px solid black; width: 55%;padding:20px;">在您使用游戏的整个生命周期内存储，直到根据您的请求删除帐户，然后将在30天内删除。</th>
        </tr>
        <tr>
        <th style="border: 1px solid black; width: 45%;padding:20px;">调查信息和调查回复的内容</th>
        <th style="border: 1px solid black; width: 55%;padding:20px;">存储期限：（a）如果您将电子邮件作为调查的一部分提供，最长6个月（以便我们跟进您），然后数据将被匿名化；或者（b）如果您没有将电子邮件作为调查的一部分提供，最长为1个月，然后数据被匿名化。</th>
        </tr>
        </table>
        </div>
        <p>以上列出的数据删除时间仅基于我们游戏客户端本身收集的信息，如果我们从其他合作伙伴（如App Store、Google Play、Facebook等）获得信息，相应的时间可能会发生变化，具体时间以披露的合作伙伴的隐私政策为准。</p>
        <p>4.2个人信息将壹直保存到其生命周期结束（如上述保留政策所述）。在销毁个人信息时，将采取措施使个人信息不可恢复或不可复制，并使用使文件不可复制的技术方法永久删除包含个人信息的电子文件。</p>
        <p>4.3如果处理和保留期终止，但由于其他原因，包括适用法律规定的目的，个人信息需要持续保留，相关个人信息将与其他类型的个人信息分开存储和维护。如果您要求我们在您的个人信息生命周期结束前销毁您的个人资料（如上述保留政策所述），我们将根据当地法律销毁您的私人信息。</p>
        <h3>第二节：妳的权利</h3>
        <p>5.本节（“您的权利”）仅适用于位于欧洲经济区的用户。对于位于其他地区的用户，请参阅本政策中的隐私条款和科锐牧服务条款。</p>
        <p>您对我们持有的有关您的个人信息享有某些权利。其中壹些仅适用于某些情况（如下所述）。我们必须响应您的请求，在不无故拖延的情况下，至少在壹个月内行使这些权利（尽管在某些情况下可能会再延长两个月）。要行使您的任何权利，请发送电子邮件至kf@科锐牧.com.</p>
        <p>5.1权力</p>
        <p>您有权访问我们持有的关于您的个人信息、我们如何使用这些信息以及我们与谁共享这些信息。您可以通过登录您的游戏帐户并联系来访问您作为帐户壹部分提供的个人信息kf@科锐牧.com.如果您认为我们持有您的任何其他个人信息，请发送电子邮件至kf@科锐牧.com.</p>
        <p>5.2便携性</p>
        <p>您有权收到我们处理的有关您的某些个人信息的副本。这包括我们在您同意的基础上或根据我们与您的合同处理的任何个人信息（例如，某些调查信息），如“我们如何使用您的个人信息”壹节所述。您有权以结构化、通用和机器可读的格式接收此信息。您也有权要求我们将该个人信息转移给另壹方。</p>
        <p>如果您希望我们将此类个人信息转移给第三方，请确保在您的请求中详细说明该方。请注意，我们只能在技术可行的情况下这样做。请注意，如果提供个人信息会干扰他人的权利（例如，提供我们持有的关于您的个人信息会泄露他人的信息或我们的商业秘密或知识产权），我们可能无法向您提供个人信息。</p>
        <p>5.3纠正</p>
        <p>您有权更正任何不准确的个人信息。您可以通过登录您的游戏帐户来访问您作为帐户壹部分提供的个人信息。如果您认为我们持有关于您的任何其他个人信息，并且这些信息不准确，请发送电子邮件至kf.科锐牧.com。</p>
        <p>5.4删除</p>
        <p>您可以通过登录您的游戏帐户来删除您的帐户或删除某些个人信息。如果您认为我们处理的任何其他个人信息希望我们删除，请发送电子邮件至kf.科锐牧.com。</p>
        <p>在以下情况下，您可以要求我们删除您的个人信息：</p>
        <ul>
        <li><p>a） 您认为我们不再需要持有此类个人信息；</p></li>
        <li><p>b） 您认为我们持有的有关您的个人信息正被我们非法处理。</p></li>
        </ul>
        <p>在我们考虑您的请求时，您也可以行使您的权利，限制我们处理您的个人信息（如下所述）。</p>
        <p>如果数据保护法有正当理由（例如，为了保护法律主张或言论自由），我们可能需要保留个人信息，但如果是这样，我们会通知您。如果您要求我们删除在游戏服务上公开的个人信息，并且有理由删除，我们将采取合理措施，试图告诉其他显示个人信息或提供个人信息链接的人也删除这些信息。</p>
        <p>6.处理仅限于存储</p>
        <p>在某些情况下，您有权要求我们停止处理我们持有的有关您的个人信息，而不是出于存储目的。但是，请注意，如果我们停止处理个人信息，如果根据数据保护法有正当理由（例如，为了辩护法律索赔或他人的保护），我们可能会再次使用它。如上所述，如果我们同意停止处理个人信息，我们将尽力告知我们已向其披露相关个人信息的任何第三方，以便他们也可以停止处理。</p>
        <p>您可以要求我们停止处理，只存储我们持有的有关您的个人信息：</p>
        <ul>
        <li><p>a） 您认为个人信息在我们验证其是否准确所需的时间内不准确</p></li>
        <li><p>b） 您希望删除个人信息，因为我们正在进行的处理是非法的，但您希望我们保留个人信息，而只是存储它；</p></li>
        <li><p>c） 您希望删除个人信息，因为它不再是我们的目的所必需的，但您要求将其存储起来以建立、行使或辩护法律索赔。</p></li>
        </ul>
        <p>7.反对</p>
        <p>您有权反对我们处理您的个人信息。在其他情况下，我们将考虑您的请求，具体如下，请发送电子邮件至kf.科锐牧.com。</p>
        <p>8.公告</p>
        <p>当我们认为有必要时，我们可能会不时向您发送公告（例如，当我们出于维护、安全、隐私或行政相关通信的目的暂时暂停游戏访问时）。您不能选择退出这些与服务相关的公告，这些公告本质上不是促销性质的。</p>
        <h3>第3节：其他选项</h3>
        <p>9.信息安全</p>
        <p>我们已经实施了管理、物理和技术安全措施，旨在保护您的信息免受丢失、盗窃、滥用、未经授权的访问、披露、更改和破坏。不过，妳应该明白，尽管我们做出了努力，但任何安全都无法保证如此无懈可击。</p>
        <p>10.链接到他人运营的网站和服务</p>
        <p>科锐牧服务条款可能包含指向其他网站、应用程序和服务的链接。由于科锐牧不运营这些其他网站、应用程序和服务，我们不能对运营这些网站、应用软件和服务的实体的隐私做法负责。我们建议您始终如壹地检查隐私政策，以了解运营商的做法。作为提醒，本政策描述了科锐牧的做法。</p>
        <p>11.变更</p>
        <p>科锐牧可能会不时更新此隐私策略。如果我们在收集、使用、保留或共享您的个人信息的方式上发生重大更改，我们将通过向您提供的最后壹个电子邮件地址发送电子邮件或发布本隐私政策所涵盖服务的更改通知来通知您。在我们发布对本政策的修改或向您提供修改通知后继续访问或使用我们的服务，即表示您同意受修改条款的约束。如果您不能接受修改后的条款，您唯壹的追索权就是停止使用我们的服务。</p>
        <p>12.国际转让</p>
        <p>我们可能会将收集到的有关您的信息转移给附属实体，或跨境转移给其他第三方，并从您的国家或管辖区转移到世界其他国家或管辖权。请注意，这些国家和司法管辖区可能与您所在的司法管辖区没有相同的数据保护法律，我们会采取措施确保有足够的保障措施，以实现本政策中所述的信息传输、信息使用和披露，包括个人信息。</p>
        <p>13.联系与投诉</p>
        <p>欢迎就这项政策提出问题、意见和要求。请通过电子邮件与我们联系：kf.科锐牧.com。</p>
        <p>如果您想投诉我们如何处理您的个人信息，请首先通过kf.科锐牧.com我们将尽力尽快处理您的请求。这不影响您向您居住或工作所在国家的数据保护机构提出索赔的权利，您认为我们违反了数据保护法。</p>
        <p>14.管辖权</p>
        <p>本政策及其相关行动将受中华人民共和国法律管辖，不考虑其法律规定的冲突。因本政策引起的或与本政策有关的任何争议应提交上海国际经济贸易仲裁委员会/上海国际仲裁中心（“SHIAC”）进行仲裁，仲裁应按照申请仲裁时有效的SHIAC仲裁规则进行。仲裁地点应为中国上海。仲裁程序使用的语言应为中文。仲裁裁决是终局的，对双方均有约束力。</p>         
        `
      },
      // 联系我们
      contactUs: {
        title: "联系我们",
        content: "如果您对此游戏有任何疑问、意见或建议。",
        contentOne: "请联系我们："
      }
    },
    
  }
}
